.subtitle {
  margin-bottom: .5rem !important;
}

.table-wrapper {
  max-height: 300px;
  overflow-y: overlay;
  font-size: 0.85em;
}

.route-list {
  list-style: none;
  padding: 0;
  padding-right: .25rem;
  margin-bottom: 0;
  max-height: 250px;
  overflow-y: auto;
}

.route-item {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
  cursor: pointer;
}

.route-item-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.route-position {
  background-color: #f5f5f5;
  color: #333;
  border-radius: 5rem;
  padding: .25rem .75rem;
  margin-right: 1rem;
}

.route-card {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: .35rem;
  padding: .5rem 1rem;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.route-item-active .route-position,
.route-item:hover .route-position {
  background-color: #2F80ED;
  color: #fff;
}

.route-item:hover .route-card {
  background-color: #2F80ED;
  color: #fff;
}