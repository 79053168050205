.no-route {
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
}

.no-route-title {
  margin-bottom: 0;
  color: #4f4f4f;
  font-weight: bold;
}

.no-route-desc {
  margin-bottom: 0;
}
