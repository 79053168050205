.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head > h5 {
  margin-bottom: 0;
}

.forms {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  background-color: #f5f5f5;
}

.table td {
  white-space: nowrap;
}

.table-list {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #777;
}

.table-table {
}

.table-table th,
.table-table td {
  padding: 0 0.25rem;
  border: none;
  vertical-align: bottom;
}

.table-table th {
  font-size: 0.95em;
}

.table-table td {
  font-size: 0.85em;
}

.table-table tr {
  background-color: transparent !important;
}

.btn {
  padding: 0.25rem 0.5rem;
  margin: 0;
}
