.subtitle {
  margin-bottom: 0.5rem !important;
}

.store-list {
  margin-left: 0;
  padding: 0;
  list-style: none;
}

.store-list > li {
  display: flex;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  align-items: center;
}

.store-list > li > span {
  margin-right: 1rem;
  flex: 20%;
}

.store-list > li > div {
  flex: 70%;
  height: 1.5rem;
  margin: 0.25rem 0;
}

.store-list > li > div:last-child {
  flex: 10% 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.store-list > li > div > button {
  padding: 0 1rem;
}

.store-container {
  display: block;
  position: relative;
  width: 50%;
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: #f5f5f5;
}

.store-container > div {
  background-color: #80e296;
  height: 100%;
}

.store-container > span {
  position: absolute;
  color: #4f4f4f;
  top: 0;
  left: 35%;
}

.certified {
  align-self: center;
  margin-left: 0.25rem;
  color: #2f80ed;
}

.input {
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.15rem 0.5rem;
}

.expires {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.expires > span {
  font-weight: bold;
  color: #4f4f4f;
  margin-right: 0.5rem;
}

.expires > input {
  width: 50%;
}
