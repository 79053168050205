.information {
  margin-bottom: 1rem;
}

.store-name {
  padding-right: 0.5rem;
  padding-left: 0.25rem;
  font-weight: bold;
  color: #4f4f4f;
}

.no-stores {
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-stores > span {
  font-weight: bold;
  color: #888;
}

.custom-row {
  flex-wrap: nowrap;
  overflow: auto;
}
