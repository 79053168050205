.auth {
  background-color: #2F80ED;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.logo {
  width: 50%;
  margin: auto;
  display: block;
}

.form-options {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.change-language {
  font-weight: 400;
  padding: 0;
}