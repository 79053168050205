.sidebar {
  min-width: 250px;
  max-width: 250px;
  height: calc(100vh - 56px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* Box shadow */
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  /* Transitions */
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar :global .links {
  background-color: #fff;
  list-style: none;
  padding: 0;
  max-height: calc(100% - 200px);
  overflow-y: auto;
  margin: 0.5rem 0;
}

.sidebar :global .links a {
  width: 100%;
  padding: 0.5rem 1.6rem;
  color: #1d2f62;
  text-decoration: none !important;
  font-size: 1.1em;
  font-weight: bold;
  display: flex;
  align-items: center;
  /* Transitions */
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar :global .links a.active,
.sidebar :global .links a:hover {
  background-color: #2f80ed;
  color: #fff;
}

.sidebar :global .links a .svg-inline--fa {
  margin-right: 1rem;
  width: 25px;
}

.sidebar :global .header {
  padding: 1rem 0;
  height: 200px;
}

.sidebar :global .header img {
  width: 50%;
  margin: auto;
  display: block;
  margin-bottom: 0.5rem;
}

.sidebar :global .header h1 {
  font-size: 1.5em;
  margin-bottom: 0;
}

/** ******************************************** */
/** Collapsed */
/** ******************************************** */

.sidebar.collapsed {
  max-width: 80px;
  min-width: 80px;
}

.sidebar.collapsed :global .links {
  max-height: calc(100% - 75px);
}

.sidebar.collapsed :global .links a {
  padding: 10px;
  font-size: 0.8em;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
}

.sidebar.collapsed :global .links a span {
  display: none;
}

.sidebar.collapsed :global .links a .svg-inline--fa {
  margin: 0;
  width: 100%;
  font-size: 1.5em;
}

.sidebar.collapsed :global .header {
  height: 75px;
}

.sidebar.collapsed :global .header img {
  margin-bottom: 0;
}

.sidebar.collapsed :global .header h1 {
  display: none;
}

/** ******************************************** */
/** Movil */
/** ******************************************** */

@media (max-width: 768px) {
  .sidebar.collapsed {
    max-width: 0px;
    min-width: 0px;
  }

  .sidebar.collapsed :global .links {
    display: none;
  }
}
