.item {
  margin: 0;
  line-height: 1.2;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #f5f5f5;
}

.item span:first-child {
  color: #4f4f4f;
}

.item span:last-child {
  font-size: 0.9em;
}
