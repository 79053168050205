.table-wrapper {
  font-size: 0.9em;
}

.td-date {
  font-size: 0.95em;
  white-space: nowrap;
}

.td-buttons {
  font-size: 0.9em;
  text-align: right;
  white-space: nowrap;
}

.btn-edit {
  font-weight: 400;
  padding: 0;
}

.go-back {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.btn-back {
  padding-left: 0px;
  font-weight: 400;
  padding: 0;
  padding-right: .5rem;
}

.h6-back {
  margin-bottom: 0;
}