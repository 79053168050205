.card-header {
  position: relative;
}

.content {
  background-color: #1d2f62;
  color: #fff;
  border-radius: 0.35rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  justify-content: space-between;
}

.white-bottom {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1rem;
  z-index: -1;
}

.left {
  display: flex;
  align-items: center;
}

.right > * {
  margin-right: 0.5rem;
}

.right > *:last-child {
  margin-right: 0;
}

.back {
  color: #fff;
  padding-left: 0;
}

.back:hover {
  color: #ddd;
}

.title {
  margin-bottom: 0;
  font-weight: bold;
}
