.table-wrapper {
  max-height: 300px;
  overflow-y: overlay;
  font-size: 0.9em;
}

.header {
  display: flex;
  align-items: flex-start;
}

.title {
  flex: 1;
}

.btn-editar {
  padding: 0;
  font-weight: 400;
}