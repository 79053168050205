.route-progress {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wrapper {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}

.line {
  height: 1rem;
  background-color: #ddd;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 25px;
}

.wrapper:first-child>.line {
  width: 50%;
  right: 0;
}

.wrapper:last-child>.line {
  width: 50%;
  left: 0;
}

.bola {
  border: 10px solid #085EC4;
  border-radius: 100%;
  width: 65px;
  height: 65px;
  margin-bottom: .5rem;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: #ccc;
}

.inactive {
  background-color: #fff;
}

.active {
  background-color: #59E7DE;
  color: #fff;
}

.bola:hover {
  background-color: #59E7DE;
  color: #fff;
}

.label {
  font-weight: bold;
  color: #2F80ED;
  text-align: center;
  font-size: 0.9em;
}