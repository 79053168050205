.route-list {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  max-height: 450px;
  overflow-y: auto;
  padding-right: .25rem;
}

.route-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.route-position {
  background-color: #2F80ED;
  color: #fff;
  border-radius: 5rem;
  padding: .5rem 1rem;
  font-weight: bold;
  margin-right: 1rem;
}

.route-card {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: .35rem;
  padding: .75rem 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
}

.route-card:hover {
  cursor: grab;
}

.card-right {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
}

.card-title {
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1rem;
}

.card-left {
  align-items: center;
  margin-right: 1.5rem;
}

.card-delete {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  color: red;
  padding: .25rem 1rem;
}

.list-pre {
  background-color: #f5f5f5;
  border-radius: .35rem;
  padding: .75rem 1.5rem;
  margin: 0;
  text-align: center;
}

.list-pre-title {
  font-weight: bold;
  font-size: 1.25em;
  margin-bottom: 0;
}

.timepicker {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.timepicker>label {
  margin-right: .5rem;
}

.timepicker > div {
  flex: 1;
}