.panel {
}

.header {
}

.body {
  display: flex;
  height: calc(100vh - 56px);
  overflow: hidden;
  position: relative;
}

.left {
}

.right {
  overflow-y: auto;
  flex-basis: 100%;
  padding: 50px 0;
}

@media (max-width: 768px) {
  .right {
    min-width: 100vw;
  }
}
