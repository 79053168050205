.navbar {
  background-color: #2f80ed !important;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.toggle {
  color: #fff;
  padding: 0rem 0.75rem;
  font-size: 1.5em;
}

.navbar :global .nav-link,
.navbar :global .nav-nolink {
  color: #fff !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: 400;
}

.navbar :global .nav-link:hover {
  color: #ddd !important;
}

.navbar :global .nav-nolink {
  display: block;
  padding: .5rem 1rem !important;
}

.btn-idioma {
  padding: 0.5rem 1rem;
  border: none;
  color: #fff !important;
}
