.certified {
  align-self: center;
  margin-left: 0.5rem;
  color: #2f80ed;
  font-size: 0.85em;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.head h5 {
  margin-bottom: 0;
}

.information {
  margin-bottom: 1rem;
}

.tabs {
  margin-bottom: 1rem;
}

.tabs button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.table-row {
}

.table-row td:nth-child(4) {
  width: 40%;
}

.table-row td:last-child {
  text-align: right;
}

.table-row td button {
  line-height: 1;
}

.subtable {
  background-color: #f5f5f5;
}

.subtable table {
  /* margin-bottom: 0; */
}

.subtable table th {
  border-top: none;
}

.subtable table tr:last-child td {
  border-bottom: none;
}
