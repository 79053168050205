.go-back {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.btn-back {
  padding-left: 0px;
  font-weight: 400;
  padding: 0;
  padding-right: .5rem;
}