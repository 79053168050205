.dropdown {
  display: inline-block;
  position: relative;
}

.btn-down {
  border-bottom-left-radius: 0;
}

.dropdown-menu {
  border: 1px solid #2a73d5;
}

.dropdown-menu > button {
  display: block;
  white-space: nowrap;
}

.left {
  right: 0;
  left: auto;
  float: left;
}
