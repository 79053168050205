.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99995;
  align-items: center;
  justify-content: center;
}

.content {
  z-index: 99999;
  overflow: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal :global .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-bottom: 2px solid #2f80ed;
}

.modal :global h6 {
  margin-bottom: 0;
  font-weight: bold;
  color: #2F80ED;
}

.modal :global .modal-close-button {
  padding: 0;
  padding-left: 1rem;
}