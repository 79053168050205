.file-selected {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.file-preview {
  background-color: #efefef;
  border-radius: .25rem;
  height: calc(2.25rem + 2px);
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  flex: 1;
  margin-right: .5rem;
  color: #333;
}

.file-preview-link {
  background-color: #efefef;
  border-radius: .25rem;
  height: calc(2.25rem + 2px);
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  flex: 1;
  margin-right: .5rem;
  color: #007bff;
}

.file-btn {
  cursor: pointer !important;
  margin-bottom: 0 !important;
  color: #fff !important;
  font-size: 1em !important;
}

.file-btn:hover {
  color: #fff !important;
}

.file-btn-remove {
  margin-right: .25rem;
}