.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99995;
  align-items: center;
  justify-content: center;
}

.content {
  z-index: 99999;
  overflow: hidden;
  width: 28em;
}

.modal :global .card-body {
  padding: 1.25em;
  padding-top: 2.5em;
  text-align: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.icon {
  font-size: 6.5em;
  margin-bottom: 1.5rem;
  color: #f8bb86;
}

.title {
  font-weight: bold;
  text-align: center;
  font-size: 2em;
}

.message {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 1.25rem;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons > :global.btn:first-child {
  margin-right: 0.5rem;
}

.buttons > :global.btn {
  margin: 0 0.25rem;
  min-width: 25%;
}
