.breadcrumb {
  background-color: #f9f9f9;
  padding: .25rem 1rem;
  border-radius: .35rem;
}

.button {
  padding: 0;
  font-weight: 400;
  vertical-align: baseline;
}