.collapse-list {
  list-style: none;
  padding: 0;
  border: 1px solid #dee2e6;
  border-radius: 0.25em;
}

.listitem-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em 1em;
  cursor: pointer;
  background-color: #f5f5f5;
  border-bottom: 1px solid #dee2e6;
}

.listitem-header :global h6 {
  color: #4f4f4f;
}

.listitem-cards {
  padding: 1rem;
  background-color: #e5e5e5;
}

.listitem-cards:global.closed {
  height: 0;
  overflow: hidden;
  padding: 0;
}

.listitem-cards > :global.card {
  margin-bottom: 0.5rem;
  overflow: hidden;
}

.listitem-cards > :global.card:last-child {
  margin-bottom: 0;
}

.listitem-cards :global .card-body {
  padding: 0.5rem 1rem;
}

.listitem-cards > :global.card p {
  margin-bottom: 0;
  font-size: 0.85em;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.card-header :global .btn-link {
  padding: 0 0.5rem;
}

.card-comment {
  font-size: 1em !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem !important;
}

.pending {
  border-color: #dc3545;
  border-left: 1em solid #dc3545;
}

.released {
  border-color: #28a745;
  border-left: 1em solid #28a745;
}

.file {
  padding: 0;
  font-size: 0.85em;
}

.go-back {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.btn-back {
  padding-left: 0px;
  font-weight: 400;
  padding: 0;
  padding-right: .5rem;
}

.h6-back {
  margin-bottom: 0;
  color: #4f4f4f !important;
  font-weight: 400 !important;
}