.section {
  margin-bottom: 1.5rem;
}

.desc-header {
  font-weight: bold;
  border-bottom: 2px solid #333;
}

.desc-horizontal {
  display: flex;
  justify-content: space-between;
  font-size: 0.85em;
}

.desc-horizontal span:first-child {
  font-weight: bold;
}

.desc-vertical {
  font-size: 0.85em;
  display: flex;
  flex-direction: column;
}

.landing-desc {
  font-size: 0.8em;
}