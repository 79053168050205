.incident-col {
  background-color: #ff7f7f;
  width: 0.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
}

.controls > :global.btn {
  margin-right: 0.25rem;
}

.controls > :global.btn:last-child {
  margin-right: 0;
}

.table-legends {
  display: flex;
}

.legend {
  display: flex;
  margin-right: 1rem;
}

.legend-color {
  width: 25px;
  height: 25px;
  border-radius: .25rem;
  margin-right: .25rem;
  background-color: #333;
}

.legend-text {}

.material-td {
  display: flex;
  align-items: center;
}

.material-td > svg {
  color: #4f4f4f;
  margin-right: .25rem;
}