/****************************************/
/** Fonts */
/****************************************/

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Bold.ttf');
  font-weight: bold;
}

/****************************************/
/** General */
/****************************************/

body {
  font-family: 'Lato';
  background-color: #e5e5e5;
}

.height-hundred-percent {
  height: 100%;
}

.height-hundred-vh {
  height: 100vh;
}

.card-subtitle {
  font-weight: bold;
  color: #085ec4;
  margin-bottom: 1rem;
}

.divider {
  padding: 0.5rem;
}

.no-margin {
  margin: 0;
}

/****************************************/
/** Fonts */
/****************************************/

.font-segrove {
  font-family: 'Times New Roman', Times, serif;
}

.font-bold {
  font-weight: bold;
}

/****************************************/
/** Forms */
/****************************************/

.form-label,
.wafo-input label {
  color: #4f4f4f;
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 0;
}

.wafo-input input,
.wafo-input select,
.wafo-input textarea {
  border: 1px solid #e0e0e0;
}

.wafo-input .form-control:focus {
  z-index: 1;
}

.wafo-input .errors {
  margin-top: 0.25rem;
  margin-bottom: 0;
  padding-left: 1.25rem;
  font-size: 0.85em;
  font-weight: bold;
  color: #eb5757;
  list-style-type: square;
}

.form-help {
  color: #777;
  font-size: 0.9em;
  margin-bottom: 0;
}

.form-divider {
  border-bottom: 1px solid #ccc;
  color: #4f4f4f;
  margin-bottom: 1rem;
}

.form-divider-with-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  color: #4f4f4f;
  margin-bottom: 1rem;
}

.form-divider-with-btn button {
  padding-right: 0;
}

.form-well-msg {
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-lineBtn-nolabel {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
}

form h5 {
  font-weight: bold;
  color: #999;
  font-size: 1.2em;
}

.form-p-divider {
  color: #ccc;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/****************************************/
/** Buttons */
/****************************************/

.btn {
  border-radius: 1.5rem;
  padding: 0.375rem 1.25rem;
  font-weight: bold;
}

.btn-s {
  color: #fff;
  background-color: #2f80ed;
  border-color: #2f80ed;
}

.btn-s:hover,
.btn-s:focus,
.btn-s:active,
.btn-s.active {
  color: #fff;
  background-color: #2a73d5;
  border-color: #2a73d5;
}

.btn-table {
  font-weight: 400;
  padding: 0.25rem .75rem;
  line-height: 1;
  font-size: 0.85em;
}

.btn-icon {
  padding: 0.375rem 1rem;
}

.with-icon > svg {
  margin-right: 0.5rem;
}

.btn-submit {
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-width: 25%;
}

/****************************************/
/** Cards */
/****************************************/

.card-panel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
}

.card-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}

.card-tabs .nav-item:first-child .nav-link {
  border-left: none;
}

/****************************************/
/** Tables */
/****************************************/

#wafotable-reload {
  color: #fff;
  background-color: #2f80ed;
  border-color: #2f80ed;
}

#wafotable-reload:hover,
#wafotable-reload:focus,
#wafotable-reload:active,
#wafotable-reload.active {
  color: #fff;
  background-color: #2a73d5;
  border-color: #2a73d5;
}

.wafotable .table td > .btn {
  margin-right: 0.25rem;
}

.table-status {
  height: 1rem;
  width: 1rem;
  background-color: #ddd;
  display: block;
  border-radius: 1rem;
  margin: 0;
}

.table-status.finished {
  background-color: #28a745;
}

.table-status.ongoing {
  background-color: #ffc107;
}

.table-noreload > .controls > .right > .form-group {
  margin: 0;
  padding-right: 0;
}

.table-noreload > .controls > .right > .btn {
  display: none;
}

.table-badge {
  width: 6rem;
}

.table-noheader .controls {
  display: none;
}

.wafotable .controls .left {
  flex: 1;
}

.wafotable .controls .left .row {
  width: 100%;
}

/****************************************/
/** Modals */
/****************************************/

.modal-warning-text {
  margin-bottom: 0;
  font-size: 0.9em;
  color: #777;
}

.modal-table-wrapper {
  max-height: 300px;
  overflow-y: overlay;
  font-size: 0.85em;
}
