.container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 200px;
  border: 2px solid #fff;
  border-radius: 0.5rem;
  overflow: hidden;
}

.container-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.container-textt {
  position: absolute;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 1.2em;
  color: #4f4f4f;
}

.container-text {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-text > span:first-child {
  font-weight: bold;
  font-size: 1.2em;
  color: #4f4f4f;
}

/** ************************ */
/** Horizontal */
/** ************************ */

.container-wrapper-h {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid #fff;
  border-radius: 0.5rem;
  overflow: hidden;
}

.container-bar-h {
  position: absolute;
  height: 100%;
  left: 0;
}

.container-text-h {
  z-index: 10;
  display: flex;
  align-items: center;
}

.container-text-h > span:first-child {
  font-weight: bold;
  color: #4f4f4f;
  margin-right: 1rem;
}

.container-text-h > span:last-child {
  font-size: 0.85em;
  color: #4f4f4f;
}