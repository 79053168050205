.table-wrapper {
  font-size: 0.9em;
}

.td-date {
  white-space: nowrap;
  font-size: 1em;
}

.td-user {}

.td-info {
  width: 50%;
}

.list {
  padding: 0;
  list-style: none;
}

.list-change {
  padding-left: 1rem;
}