.table-wrapper {
  max-height: 300px;
  overflow-y: overlay;
  font-size: 0.85em;
}

.warning-text {
  margin-bottom: 0;
  font-size: 0.9em;
  color: #777;
}

.go-back {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.btn-back {
  padding-left: 0px;
  font-weight: 400;
  padding: 0;
  padding-right: .5rem;
}

.h6-back {
  margin-bottom: 0;
}